<template>
  <div id="edit">
    <nav-bar
      :title="file === null ? $t('edit.page_title') : `${$t('edit.page_title')} - ${file.original.name}`"
      :options="file === null ? null : navbarOptions"
      @option-selected="onOptionSelected"
    />

    <div v-if="file === null" class="container">
      <open-file @select-files="onFilesSelected"/>
    </div>

    <div v-else class="viewport">
      <draggable v-model="file.pages" class="page-list" item-key="number" :delay="300" :delayOnTouchOnly="true">
        <template #item="{element: page, index}">
          <div :class="['page', {active: page.active}]" @click="selectPage(page)" @contextmenu.prevent="selectPage(page)">
            <div class="info">
              <div>{{index+1}}</div>
            </div>

            <div class="preview-container">
              <img v-if="page.previewUrl" :src="page.previewUrl" class="preview">

              <div v-else class="loading">
                <i class="las la-circle-notch la-2x la-spin"></i>
              </div>
            </div>
          </div>
        </template>
      </draggable>

      <div class="page-editor" ref="editor">
        <div v-if="page" class="wrapper">
          <img :src="page.previewUrl" class="preview" ref="page">

          <div class="text-layer-wrapper">
            <div class="text-layer" ref="pageText"></div>
          </div>
        </div>

        <div v-if="page" class="zoom-settings">
          <button :class="['btn-round', {active: settings.editor.zoom.auto}]" @click="setZoom(0)" :tooltip="$t('edit.zoom.fit_to_window')"><i class="las la-expand"></i></button>
          <button class="btn-round" @click="setZoom(-1)" :tooltip="$t('edit.zoom.zoom_out')"><i class="las la-search-minus"></i></button>
          <input type="number" v-model="settings.editor.zoom.value" @input="setZoom()" :min="1" :max="settings.editor.zoom.max" onfocus="this.select()">
          <button class="btn-round" @click="setZoom(1)" :tooltip="$t('edit.zoom.zoom_in')"><i class="las la-search-plus"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import OpenFile from '@/components/OpenFile.vue';
import draggable from 'vuedraggable';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import UploadService from '@/UploadService.js';

export default {
  name: 'edit',
  components: {
    NavBar,
    OpenFile,
    draggable,
  },
  data() {
    return {
      file: null,
      page: null,
      settings: {
        editor: {
          zoom: {
            value: 100,
            auto: true,
            steps: [12.5, 25, 50, 100, 150, 200, 400, 800],
            min: 12.5,
            max: 800,
          }
        }
      },
      navbarOptions: [
        {
          key: 'file',
          actions: [
            {
              key: 'open',
              icon: 'las la-file-import',
            },
            {
              key: 'append',
              icon: 'las la-link',
            },
          ],
        },
        {
          key: 'page',
          actions: [
            {
              key: 'duplicate',
              icon: 'lar la-copy',
            },
            {
              key: 'remove',
              icon: 'lar la-trash-alt',
            },
          ],
        },
      ],
    }
  },
  methods: {
    async onOptionSelected(group, action) {
      switch(group) {
        case 'file': {
          switch(action) {
            case 'open':
              this.onFilesSelected(await UploadService.uploadPdfFiles());
              return;
            case 'append':

              return;
          }
        }
        case 'page': {
          switch(action) {
            case 'duplicate':

              return;
            case 'remove':

              return;
          }
        }
      }
    },
    async onFilesSelected(pdfFileList) {
      if(pdfFileList.isEmpty()) return;

      this.file = pdfFileList.files[0];
      await this.file.generatePagesInfo(2);

      if(this.page === null) this.selectPage(this.file.pages[0]);
    },
    selectPage(page) {
      this.file.pages.forEach(page => page.active = false);
      this.page = page;
      this.page.active = true;

      this.setPreviewHeight(this.page.viewport);
      this.$nextTick(() => {
        this.renderTextLayer();
      });
    },
    setPreviewHeight(viewport) {
      let scale = 1;

      if(!this.settings.editor.zoom.auto) {
        scale = this.settings.editor.zoom.value / 100;
      } else {
        const padding = parseFloat(getComputedStyle(this.$refs.editor, null).getPropertyValue('padding-top')) * 2;
        const {width: editorWidth, height: editorHeight} = this.$refs.editor.getBoundingClientRect();
        
        scale = Math.min((editorWidth - padding) / viewport.width, (editorHeight - padding) / viewport.height);
      }

      this.settings.editor.zoom.value = Math.round(scale * 1000) / 10;
      this.$nextTick(() => {
        this.$refs.page.style.height = `${viewport.height * scale}px`;
        this.$refs.pageText.style.transform = `scale(${scale})`;
      });
    },
    renderTextLayer() {
      this.$refs.pageText.innerHTML = '';

      pdfjsLib.renderTextLayer({
        textContent: this.page.content,
        container: this.$refs.pageText,
        viewport: this.page.viewport,
        enhanceTextSelection: true,
      });
    },
    setZoom(direction = null) {
      switch(direction) {
        case 0:
          this.settings.editor.zoom.auto = true;
          break;
        case -1: {
          const value = this.settings.editor.zoom.steps.slice().reverse().find(step => step < this.settings.editor.zoom.value);

          this.settings.editor.zoom.auto = false;
          this.settings.editor.zoom.value = value || this.settings.editor.zoom.min;
          break;
        }
        case 1: {
          const value = this.settings.editor.zoom.steps.find(step => step > this.settings.editor.zoom.value);

          this.settings.editor.zoom.auto = false;
          this.settings.editor.zoom.value = value || this.settings.editor.zoom.max;
          break;
        }
        default:
          this.settings.editor.zoom.auto = false;
          this.settings.editor.zoom.value = Math.min(Math.max(this.settings.editor.zoom.value, 1), this.settings.editor.zoom.max);
          break;
      }

      this.setPreviewHeight(this.page.viewport);
    },
    onResize() {
      if(this.page === null) return;

      this.setPreviewHeight(this.page.viewport);
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<style scoped>
#edit {
  display: flex;
  flex-direction: column;
}

.viewport {
  position: relative;
  flex-grow: 1;
  min-height: 0;
  display: flex;
  overflow: hidden;
}

.page-list {
  flex: 0 0 14rem;
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start; */
  overflow-y: auto;
  background-color: var(--light-color);
  border-right: 1px solid var(--border-light-color);
}

.page-list .page {
  /* flex: 0 0 14rem; */
  padding: .5rem 1rem;
  display: flex;
  transition: background-color .2s ease;
}

.page-list .page.active {
  background-color: rgba(0,0,0,.1);
}

.page-list .info {
  flex: 0 0 1.5rem;
  margin-right: .5rem;
  user-select: none;
}

.page-list .preview-container {
  max-height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  user-select: none;
}

.page-list .preview {
  max-height: 12rem;
  max-width: 100%;
  border: 2px solid var(--border-light-color);
  border-radius: .2rem;
  transition: border-color .2s ease;
  pointer-events: none;
}

.page-list .page.active .preview {
  border-color: var(--primary-color);
}

.page-list .loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;
  width: 100%;
  border: 1px solid var(--border-light-color);
  border-radius: .2rem;
  background-color: #fff;
}

.page-editor {
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
  padding: 1rem;
}

.page-editor .wrapper {
  margin: auto;
  box-shadow: 3px 3px 12px 0 rgba(0,0,0,0.15);
  display: inline-flex;
  position: relative;
}

.page-editor .text-layer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
}

.page-editor .text-layer {
  position: relative;
  text-align: initial;
  line-height: 1;
  transform-origin: 0% 0%;
  color: transparent;
}

.page-editor .text-layer ::selection {
  background-color: rgba(72, 191, 132, .5);
  color: transparent;
}

.page-editor .preview {
  user-select: none;
}

.zoom-settings {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  border-radius: 1rem;
  background-color: var(--light-color);
  z-index: 10;
  box-shadow: 3px 3px 12px 0 rgba(0,0,0,0.15);
}

.zoom-settings input {
  width: 4rem;
  height: 2rem;
  padding: 0 .25rem;
}

.zoom-settings .active {
  color: var(--primary-color);
}
</style>